<template>
    <!-- onboarding section start -->
    <section class="home">
        <video autoplay muted loop class="cooking-video">
            <source src="/assets/images/video.mp4" type="video/mp4" />
        </video>

        <div class="d-flex justify-content-center mt-3">
            <span class="text-white font-weight-bolder" style="font-size: 30pt;" v-text="branchName"></span>
        </div>

        <div class="d-flex justify-content-center mt-3">
            <span class="card text-white font-weight-bolder d-flex justify-content-center align-items-center" 
                  style="width:90px;height:50px;font-size: 20pt;background-color: rgba(154, 188, 39, 0.7);" 
                  v-text="tableNumber"></span>
        </div>

        <div class="theme-content">
        <div class="custom-container bg-transparent">
            <img class="img-fluid logo" src="/assets/images/svg/tamani.png" alt="logo" style="width:200px;position: absolute;margin-top:-150px"/>
            <ul class="slogan">
            <li><img src="/assets/images/svg/slogan-star.svg" alt="star" /></li>
            <li>Self Ordering</li>
            <li><img src="/assets/images/svg/slogan-star.svg" alt="star" /></li>
            </ul>
            <p>Find and try various foods from here</p>
            <router-link :to="{name : 'store.main'}" class="btn theme-btn start-btn w-100" role="button">Start Order</router-link>
        </div>
        </div>
    </section>
    <!-- onboarding section end -->
</template>

<script>
export default {
    name: 'BranchView',
    data(){
        return{
            branch: null,
            table: null,
            token: null,
        }
    },

    computed: {
        branchName(){
            return this.$route.params.branch;
        },

        tableNumber(){
            return this.$route.params.table;
        }
    }
}
</script>

<style>

</style>