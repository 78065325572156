<template>
    <div class="content-wrapper" style="padding-top: 30px">
        <h1 class="title-text">Manage Restaurant and Outlet</h1>

        <div class="restaurant-wrapper">
            <Splide :options="{ rewind: true, 
                                perPage: 4, 
                                pagination: false,
                                type: 'loop', 
                                drag: 'free',
                                arrows: true}" 
                    class="slider-wrapper"
                    aria-label="My Favorite Images">
                <SplideSlide v-for="ppl in outlet" :key="ppl.id">
                    <div class="restaurant-card">
                        <div class="card__img" v-if="ppl.image">
                            <img class="restaurant-img" :src="getRestaurantImg(ppl.image)" alt="">
                        </div>
                        <div class="card__subtitle">{{ ppl.type }}</div>
                        <div class="card__wrapper">
                            <div class="card__title">{{ ppl.name }}</div>
                            <div class="card__icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" style="user-select: none; width: 100%; height: 100%; display: inline-block; fill: rgb(224, 223, 220); flex-shrink: 0; cursor: auto;" color="rgb(224, 223, 220)"><g color="rgb(224, 223, 220)"><circle cx="128" cy="128" r="96" opacity="0.2"></circle><circle cx="128" cy="128" r="96" fill="none" stroke="rgb(224, 223, 220)" stroke-miterlimit="10" stroke-width="16"></circle><polyline points="134.1 161.9 168 128 134.1 94.1" fill="none" stroke="rgb(224, 223, 220)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline><line x1="88" y1="128" x2="168" y2="128" fill="none" stroke="rgb(224, 223, 220)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line></g></svg></div>
                        </div>
                    </div> 
                </SplideSlide>
            </Splide>
        </div>

        <div class="nav-selector">
            <label class="radio">
                <input type="radio" name="radio" value="1" v-model="selectedNav" checked="true">
                <span class="name">Restaurant</span>
            </label>
            <label class="radio">
                <input type="radio" name="radio" value="2" v-model="selectedNav">
                <span class="name">Outlet</span>
            </label>
            
        </div>

        <div class="content restaurant-table-wrapper" v-if="selectedNav == 1">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="table-card">
                            <div class="card-header">
                                <h3 class="card-title">Data Restaurant</h3>
                            </div>
                
                            <div class="card-body">
                                <router-link :to="{ name: 'add-restaurant' }">
                                    <button class="btn btn-theme align-self-end">
                                        <i class="nav-icon fas fa-store" id="nav-icon" color="white"> Add Restaurant</i>
                                    </button>
                                </router-link>
        
                                <table id="datatable" class="table thead-dark table-bordered table-striped mt-2">
                                    <thead class="thead-dark">
                                        <tr>
                                        <th>ID</th>
                                        <th>Nama</th>
                                        <th>Email</th>
                                        <th>Description</th>
                                        <th>image</th>
                                        <th>Total Outlet</th>
                                        <th width="120px">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="rest in restaurant" :key="rest.id">
                                            <td>{{ rest.id }}</td>
                                            <td>{{ rest.name }}</td>
                                            <td>{{ rest.email }}</td>
                                            <td><small>{{ rest.description }}</small></td>
                                            <td><img class="restaurant-thmb" :src="getRestaurantImg(rest.image)" alt=""></td>
                                            <td>{{ rest.outlet }}</td>
                                            <td>
                                            <div class="d-flex justify-content-arround">
                                                <router-link :to="{ name: 'edit-restaurant', params: { id : rest.id }}">
                                                    <button class="btn btn-sm btn-outline-success">Edit</button>
                                                </router-link>
                                                <button class="btn btn-sm btn-outline-danger ml-3" @click="show(rest.id, rest.name, 'restaurant')">Delete</button>
                                            </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content restaurant-table-wrapper" v-if="selectedNav == 2">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="table-card">
                            <div class="card-header">
                                <h3 class="card-title">Data Outlet</h3>
                            </div>
                
                            <div class="card-body">
                                <router-link :to="{ name: 'add-outlet' }">
                                    <button class="btn btn-info align-self-end">
                                        <i class="nav-icon fas fa-house-user" id="nav-icon" color="white"> Add Outlet</i>
                                    </button>
                                </router-link>
                                <table id="datatable" class="table table-bordered table-striped mt-2">
                                    <thead class="thead-black">
                                        <tr>
                                        <th>ID</th>
                                        <th>Nama Outlet</th>
                                        <th>Outlet Type</th>
                                        <th>Description</th>
                                        <th>Map</th>
                                        <th>image</th>
                                        <th width="120px">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in outlet" :key="item.id">
                                            <td>{{ item.id }}</td>
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.type }}</td>
                                            <td>{{ item.description }}</td>
                                            <td><small>{{ item.maps }}</small></td>
                                            <td><img class="restaurant-thmb" :src="getRestaurantImg(item.image)" alt=""></td>
                                            <td>
                                            <div class="d-flex justify-content-arround">
                                                <router-link :to="{ name: 'edit-outlet', params: { id : item.id }}">
                                                    <button class="btn btn-sm btn-outline-success">Edit</button>
                                                </router-link>
                                                <button class="btn btn-sm btn-outline-danger ml-3" @click="show(item.id, item.name, 'outlet')">Delete</button>
                                            </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ModalVue :itemid="selectedId" :itemname="selectedName" :itemuri="selectedURI" :itemredirect="redirect" v-show="showModal" @closed="onClickChild" />
    </div>
</template>

<script>
import axios from 'axios';

import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css/sea-green';
import ModalVue from '@/components/Modal.vue';

export default {
    name: 'RestaurantView',
    components: {
        Splide,
        SplideSlide,
        ModalVue
    },
    data(){
        return {
            selectedNav: 1,
            isRestaurant: true,
            isOutlet: false,
            restaurant: [],
            outlet: [],
            showModal: false,
            selectedId: null,
            selectedName: null,
            selectedURI: null,
            redirect: null,
        }
    },
    mounted(){
        this.getRestaurant();
        this.getOutlet();
    },
    methods: {
        async getOutlet(){
            const validToken = this.$store.getters.GET_AUTH_TOKEN;
            const config = {
                headers: { Authorization: `Bearer ` + validToken}
            }

            try{
                let { data } = await axios.get('/outlet', config)
                this.outlet = data.data
                console.log(this.outlet)
            } catch(e) {
                console.log(e)
            }
        },
        async getRestaurant(){
            const validToken = this.$store.getters.GET_AUTH_TOKEN;
            const config = {
                headers: { Authorization: `Bearer ` + validToken}
            }

            try{
                let { data } = await axios.get('/restaurant', config)
                this.restaurant = data.data
            } catch(e) {
                console.log(e)
            }
        },
        getRestaurantImg(filename){
            return this.assets + '/images/restaurant/' + filename;
        },
        show(id, name, uri){
            this.selectedId = id;
            this.selectedName = name;
            this.selectedURI = uri;
            this.showModal = true;
            this.redirect = this.$route.name;
        },
        onClickChild (value) {
          this.showModal = value;
        },
    }
}
</script>

<style>

</style>