<template>
    <div>
        <aside class="main-sidebar sidebar-dark-info elevation-4">
        <!-- Brand Logo -->
        <a href="/" class="brand-link">
            <img src="assets/images/logo.png" 
                alt="">
        </a>

        <!-- Sidebar -->
        <div class="sidebar">
            <!-- Sidebar user panel (optional) -->
            <div class="user-panel mt-3 pb-3 mb-3 d-flex" :class="{active: $route.name==='profile'}">
                <div class="image">
                    <img v-bind:src="getProfileImg(usrImg)" id="profile-pic"
                         class="img-circle elevation-2" alt="User Image" 
                         style="width: 30px; height: 30px; object-fit: cover;">
                </div>
                <div class="info">
                    <router-link class="d-block" :to="{ name: 'profile' }">{{ usrName }}</router-link>
                </div>
            </div>

            <!-- Sidebar Menu -->
            <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <div class="user-panel">
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'dashboard' }" :class="{active: $route.name==='dashboard'}" >
                            <i class="nav-icon fas fa-home" id="nav-icon"
                               color="white"></i>
                            <p>Dashboard</p>
                        </router-link>
                    </li>
                </div>

                <div class="user-panel" v-if="isAdmin">
                    <li class="nav-header" style="letter-spacing: 2pt;">ACTION</li>
                    <li class="nav-item">
                    <router-link class="nav-link" :to="{name : 'restaurant'}" :class="{active: $route.name==='restaurant'}">
                        <i class="nav-icon fas fa-store"></i>
                        <p> Restaurant</p>
                    </router-link>
                    </li>

                    <li class="nav-item">
                    <router-link class="nav-link" :to="{name : 'category'}" :class="{active: $route.name==='category'}">
                        <i class="nav-icon fas fa-layer-group"></i>
                        <p> Category</p>
                    </router-link>
                    </li>

                    <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'food' }" :class="{active: $route.name==='food'}" >
                        <i class="nav-icon fas fa-list"></i>
                        <p> Menu</p>
                    </router-link>
                    </li>

                    <li class="nav-item">
                    <router-link :to="{name: 'settings'}" class="nav-link" :class="{active: $route.name==='settings'}">
                        <i class="nav-icon fas fa-bars"></i>
                        <p> Setting</p>
                    </router-link>
                    </li>   
                </div>

            </ul>
            </nav>
        </div>
        </aside>
    </div>
</template>

<script>
export default {
    name: 'SidebarItem',
    computed: {
        isAdmin(){
            let role = this.$store.getters.GET_AUTH_INFO.role;
            if(role == 1){
                return true;
            } else {
                return false;
            }
        },
        usrName(){
            return this.$store.getters.GET_AUTH_INFO.name;
        },
        usrImg(){
            return this.$store.getters.GET_AUTH_INFO.image;
        }
    },
    methods: {
        getProfileImg(filename){
            return this.assets + '/images/profile/' + filename;
        }
    }
}
</script>

<style>

</style>