<template>
    <div class="content-wrapper" style="padding-top: 30px">
        <h1 class="title-text">Manage Category</h1>
        <div class="nav-selector">
            <label class="radio" v-for="rst in restaurant" :key="rst.id">
                <input type="radio" name="radio" :value="rst.id" v-model="selectedNav">
                <div class="name">{{ rst.name }}</div>
            </label>
        </div>

        <div class="content restaurant-table-wrapper" v-for="rst in restaurant" :key="rst.id">
            <div class="container-fluid" v-show="selectedNav == rst.id">
                <div class="row">
                    <div class="col-12">
                        <div class="table-card">
                            <div class="card-header">
                                <h3 class="card-title">Category {{ rst.name }}</h3>
                            </div>
                
                            <div class="card-body">
                                <router-link :to="{ name: 'add-category' }">
                                    <button class="btn btn-theme align-self-end">
                                        <i class="nav-icon fas fa-layer-group" id="nav-icon" color="white"> Add Category</i>
                                    </button>
                                </router-link>
        
                                <table id="datatable" class="table thead-dark table-bordered table-striped mt-2">
                                    <thead class="thead-dark">
                                        <tr>
                                        <th>ID</th>
                                        <th>Nama</th>
                                        <th>Description</th>
                                        <th>image</th>
                                        <th width="120px">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="ctg in rst.category" :key="ctg.id">    
                                            <td>{{ ctg.id }}</td>
                                            <td>{{ ctg.name }}</td>
                                            <td><small>{{ ctg.description }}</small></td>
                                            <td><img class="restaurant-thmb" :src="getCategoryImg(ctg.image)" alt=""></td>
                                            <td>
                                                <div class="d-flex justify-content-arround">
                                                    <router-link :to="{ name: 'edit-category', params: { id : ctg.id }}">
                                                        <button class="btn btn-sm btn-outline-success">Edit</button>
                                                    </router-link>
                                                    <button class="btn btn-sm btn-outline-danger ml-3" @click="show(ctg.id, ctg.name, 'category')">Delete</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ModalVue :itemid="selectedId" :itemname="selectedName" :itemuri="selectedURI" :itemredirect="redirect" v-show="showModal" @closed="onClickChild" />
    </div>
</template>

<script>
import axios from 'axios'
import ModalVue from '@/components/Modal.vue';

export default {
    name: 'RestaurantView',
    components: {
        ModalVue
    },
    data(){
        return {
            restaurant: [],
            selectedNav: 1,
            selectedId: null,
            selectedName: null,
            selectedURI: null,
            showModal: false,
            redirect: null,
        }  
    },
    mounted(){
        this.getRestaurant()
    },
    computed: {
        selectedCategory(){
            const ctg = this.selectedNav - 1;
            return ctg;
        }
    },
    methods: {   
        async getRestaurant(){
            const validToken = this.$store.getters.GET_AUTH_TOKEN;
            const config = {
                headers: { Authorization: `Bearer ` + validToken}
            }

            try {
                let { data } = await axios.get('/restaurant-category', config)
                this.restaurant = data.data
                console.log(data.data)
            } catch(e){
                console.log(e)
            }
        },
        getCategoryImg(filename){
            return this.assets + '/images/categories/' + filename;
        }, 
        show(id, name, uri){
            this.selectedId = id;
            this.selectedName = name;
            this.selectedURI = uri;
            this.showModal = true;
            this.redirect = this.$route.name
        },
        onClickChild(value){
            this.showModal = value
        }
    }
}
</script>

<style>

</style>