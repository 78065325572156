<template>
    <div>
        <div class="content-wrapper">
            <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Profile</h1>
                </div>
                </div>
            </div><!-- /.container-fluid -->
        </div>

            <!-- Main content -->
            <div class="content">
            <div class="container-fluid">
                <div class="row">
                <div class="col-md-3">
                    <!-- Profile Image -->
                    <div class="card card-info card-outline">
                    <div class="card-body box-profile">
                        
                        <div class="text-center" v-if="gImage">
                        <img class="profile-user-img img-fluid img-circle"
                            v-bind:src="getProfileImg(user_image)"
                            alt="User profile picture"
                            style="width: 150px; height: 150px; object-fit: cover;">
                        </div>

                        <div class="text-center" v-if="!gImage">
                            <div v-for="img in images" :key="img">
                                <img class="profile-user-img img-fluid img-circle"
                                :src="img" alt="User profile picture"
                                style="width: 150px; height: 150px; object-fit: cover;">
                            </div>
                        </div>

                        <h3 class="profile-username text-center mt-5">{{ user_name }}</h3>

                        <p class="text-muted text-center">{{ user_nip }}</p>

                        <div class="bg-info d-block text-center mt-5" style="font-size: 15pt; padding: 5px"><b>Change Photos</b></div>
                        <input type="file" class="form-control" name="image" id="image" ref="file" @input="handleSelects">
                    </div>
                    </div>
                </div>

                <!-- /.col -->
                <div class="col-md-9">
                    <div class="card">
                    <div class="card-body">
                        <div class="tab-content">
                            <div class="" id="settings">
                            <div class="form-horizontal">
                            <div class="form-group row">
                                <label for="inputName" class="col-sm-2 col-form-label">Name</label>
                                <div class="col-sm-10">
                                <input type="text" class="form-control" id="name" v-model="user_name">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="inputName" class="col-sm-2 col-form-label">NIP</label>
                                <div class="col-sm-10">
                                <input type="text" class="form-control" id="nip" v-model="user_nip">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="inputEmail" class="col-sm-2 col-form-label">Email</label>
                                <div class="col-sm-10">
                                <input type="email" class="form-control" id="email" v-model="user_email">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="inputName2" class="col-sm-2 col-form-label">Phone</label>
                                <div class="col-sm-10">
                                <input type="text" class="form-control" id="phone" v-model="user_phone">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="inputExperience" class="col-sm-2 col-form-label">Address</label>
                                <div class="col-sm-10">
                                <textarea class="form-control" id="alamat" v-model="user_address"></textarea>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="inputName2" class="col-sm-2 col-form-label">Change Password</label>
                                <div class="col-sm-10">
                                <input type="password" class="form-control" id="phone" v-model="password">
                                </div>
                            </div>         
                            <div class="form-group row">
                                <div class="offset-sm-2 col-sm-10">
                                <button type="buttom" class="theme-btn" @click="updateProfile">Save</button>
                                </div>
                            </div>
                            </div>
                            </div>
                        </div>
                        <!-- /.tab-content -->
                    </div><!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                </div>
                <!-- /.col -->
                </div>
                <!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'ProfileView',
    data(){
        return{
            user_id: null,
            user_name: null,
            user_nip: null,
            user_email: null,
            user_phone: null,
            user_address: null,
            user_image: null,

            password: null,
            image: [],
            images: [],
            image_name: null,
            gImage: 'yes',
        }
    },
    mounted(){
        this.user_id = this.$store.getters.GET_AUTH_INFO.id;
        this.user_name = this.$store.getters.GET_AUTH_INFO.name;
        this.user_nip = this.$store.getters.GET_AUTH_INFO.nip;
        this.user_email = this.$store.getters.GET_AUTH_INFO.email;
        this.user_phone = this.$store.getters.GET_AUTH_INFO.phone;
        this.user_address = this.$store.getters.GET_AUTH_INFO.alamat;
        this.user_image = this.$store.getters.GET_AUTH_INFO.image;
    },
    methods: {
        getProfileImg(filename){
            return this.assets + '/images/profile/' + filename;
        },
        updateProfile(){
            const validToken = this.$store.getters.GET_AUTH_TOKEN;

            let formData = new FormData();
            formData.append('image', this.image);
            formData.append('id', this.user_id);
            formData.append('name', this.user_name);
            formData.append('nip', this.user_nip);
            formData.append('email', this.user_email);
            formData.append('phone', this.user_phone);
            formData.append('address', this.user_address);

            if(this.password != null){
                formData.append('password', this.password);
            }

            axios.post('/profile', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': '*',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ` + validToken,
                }
            })
            .then(() => {
                window.location.href = "/profile"
            }).catch(err => {
                console.log(err)
            });
        },
        handleSelects(e) {
            this.images = [];
            this.gImage = null;
            this.image_name = e.target.files[0].name;
            this.image = e.target.files[0];
            let fileList = Array.prototype.slice.call(e.target.files);
            fileList.forEach(f => {
                if(!f.type.match("image.*")) {
                    return;
                }

                let reader = new FileReader();
                let that = this;

                reader.onload = function (e) {
                    that.images.push(e.target.result);
                }

                reader.readAsDataURL(f); 
            })
        },
    }
} 
</script>

<style>

</style>