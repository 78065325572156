import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import HomeView from '../views/Home.vue'
import ProfileView from '../views/pages/Profile.vue'
import LoginView from '../views/auth/Login.vue'
import ResetView from '../views/auth/password/Reset.vue'
import ManageUserView from '../views/pages/ManageCustomer.vue'
import ForgotView from '../views/auth/password/Forgot.vue'
import ResetSuccessView from '../views/auth/password/ResetSuccess.vue'
import ForbiddenView from '../views/auth/ForbiddenView.vue'
import AddCustomerView from '../views/pages/AddCustomer.vue'
import EditCustomerView from '../views/pages/EditCustomer.vue'
import ManageObatView from '../views/pages/ManageObat.vue'
import AddObat from '../views/pages/AddObat.vue'
import EditObatView from '../views/pages/EditObat.vue'
import ManageResepview from '../views/pages/ManageResep.vue'
import AddResepView from '../views/pages/AddResep.vue'
import AddTransactionView from '../views/pages/AddTransaction.vue'
import BranchView from '../views/store/BranchView.vue'
import MainView from '../views/store/MainView.vue'

import RestaurantView from '../views/RestaurantView.vue'
import AddRestaurantView from '../views/AddRestaurantView.vue'
import EditRestaurantView from '../views/EditRestaurantView.vue'
import AddOutletView from '../views/AddOutletView.vue'
import EditOutletView from '../views/EditOutletView.vue'
import CategoryView from '../views/CategoryView.vue'
import AddCategoryView from '../views/AddCategoryView.vue'
import EditCategoryView from '../views/EditCategoryView.vue'
import FoodView from '../views/FoodView.vue'
import AddFoodView from '../views/AddFoodView.vue'
import EditFoodView from '../views/EditFoodView.vue'
import SettingsView from '../views/SettingsView.vue'

const routes = [
  {path:'/', name: 'login', component: LoginView, meta: { guest: true }},
  {path: '/reset_password', name: 'reset', component: ResetView, meta: { guest: true }}, 
  {path: '/reset_success', name: 'reset_success', component: ResetSuccessView, meta: { guest: true}},
  {path: '/forgot', name: 'forgot', component: ForgotView, meta: { guest: true }},
  {path: '/dashboard', name: 'dashboard', component: HomeView, meta: { auth: true }},
  {path: '/profile', name: 'profile', component: ProfileView, meta: { auth: true }},
  {path: '/manage', name: 'manageuser', component: ManageUserView, meta: { admin: true }},
  {path: '/403', name: 'forbidden', component: ForbiddenView, meta: { guest : true}},
  {path: '/addcustomer', name: 'addcustomer', component: AddCustomerView, meta: { admin: true }},
  {path: '/editcustomer/:id', name: 'editcustomer', component: EditCustomerView, meta: { admin: true }},
  {path: '/obat', name: 'manageobat', component: ManageObatView, meta: { admin: true }},
  {path: '/addobat', name: 'addobat', component: AddObat, meta: { admin: true }},
  {path: '/editobat/:id', name: 'editobat', component: EditObatView, meta: { admin: true }},
  {path: '/resep', name: 'manageresep', component: ManageResepview, meta: { admin: true }},
  {path: '/addresep', name: 'addresep', component: AddResepView, meta: { admin: true }},
  {path: '/addtransaction', name: 'addtransaction', component: AddTransactionView, meta: { admin: true }},
  {path: '/home/:branch/:table/:token', name: 'store.branch', component: BranchView, meta: { guest:true}},
  {path: '/main', name: 'store.main', component: MainView, meta: { guest:true}},


  {path: '/restaurant', name: 'restaurant', component: RestaurantView, meta: { admin: true }},
  {path: '/add-restaurant', name: 'add-restaurant', component: AddRestaurantView, meta: { admin: true }},
  {path: '/edit-restaurant/:id', name: 'edit-restaurant', component: EditRestaurantView, meta: { admin: true }},
  {path: '/add-outlet', name: 'add-outlet', component: AddOutletView, meta: { admin: true }},
  {path: '/edit-outlet/:id', name: 'edit-outlet', component: EditOutletView, meta: { admin: true }},
  {path: '/category', name: 'category', component: CategoryView, meta: { admin: true }},
  {path: '/add-category', name: 'add-category', component: AddCategoryView, meta: { admin: true }},
  {path: '/edit-category/:id', name: 'edit-category', component: EditCategoryView, meta: { admin: true }},
  {path: '/food', name: 'food', component: FoodView, meta: { admin: true }},
  {path: '/add-food', name: 'add-food', component: AddFoodView, meta: { admin: true }},
  {path: '/edit-food/:id', name: 'edit-food', component: EditFoodView, meta: { admin: true }},
  {path: '/settings', name: 'settings', component: SettingsView, meta: { admin: true }},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.auth)){
    if (!store.getters.GET_AUTH_STATUS) {
      next({
        name: 'login'
      })
    } else {
      next()
    }
  } else if(to.matched.some(record => record.meta.guest)){
    if (store.getters.GET_AUTH_STATUS) {
      next({
        name: 'dashboard'
      })
    } else {
      next()
    }
  } else if(to.matched.some(record => record.meta.admin)){
    if (!store.getters.GET_AUTH_STATUS){
      next({
        name: 'login'
      })
    } else if (!store.getters.GET_AUTH_INFO.role) {
      next({
        name: 'dashboard'
      })
    } else {
      next()
    }
  } else if(to.matched.some(record => record.meta.reset)){
    if (store.getters.GET_AUTH_STATUS){
      next({
        name: 'dashboard'
      })
    } else if (!store.getters.GET_AUTH_RESET) {
      next({
        name: 'login'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
