<template>
  <!-- panel-space start -->
  <section class="panel-space"></section>
  <!-- panel-space end -->

  <!-- bottom navbar start -->
  <div class="navbar-menu">
    <ul>
      <li :class="{active: $route.name==='store.main'}">
        <router-link :to="{name: 'store.main'}">
          <div class="icon">
            <img class="unactive" src="assets/images/svg/home.svg" alt="home" />
            <img class="active" src="assets/images/svg/home-fill.svg" alt="home" />
          </div>
          <span class="active">Home</span>
        </router-link>
      </li>
      <li :class="{active: $route.name==='store.offer'}">
        <a href="offer.html">
          <div class="icon">
            <img class="unactive" src="assets/images/svg/offer.svg" alt="offer" />
            <img class="active" src="assets/images/svg/offer-fill.svg" alt="offer" />
          </div>
          <span>Offer</span>
        </a>
      </li>

      <li>
        <a href="cart.html">
          <div class="icon">
            <img class="unactive" src="assets/images/svg/cart.svg" alt="cart" />
            <img class="active" src="assets/images/svg/cart-fill.svg" alt="cart" />
          </div>
          <span>Cart</span>
        </a>
      </li>
      <li :class="{active: $route.name==='store.profile'}">
        <a href="profile.html">
          <div class="icon">
            <img class="unactive" src="assets/images/svg/user.svg" alt="user.svg" />
            <img class="active" src="assets/images/svg/user-fill.svg" alt="user-fill.svg" />
          </div>
          <span>Profile</span>
        </a>
      </li>
    </ul>
  </div>
  <!-- bottom navbar end -->
</template>

<script>
export default {
    name:'MobilebarView'
}
</script>

<style>

</style>