<template>
  <div class="content-wrapper">
    <div class="demo-page">
      <div class="demo-page-navigation">
        <nav>
          <ul>
            <li>
              <a href="#installation">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-tool">
                  <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
                </svg>
                Contact Information</a>
            </li>
            <li>
              <a href="#structure">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers">
                  <polygon points="12 2 2 7 12 12 22 7 12 2" />
                  <polyline points="2 17 12 22 22 17" />
                  <polyline points="2 12 12 17 22 12" />
                </svg>
                Welcome Text</a>
            </li>
            <li>
              <a href="#input-types">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-align-justify">
                  <line x1="21" y1="10" x2="3" y2="10" />
                  <line x1="21" y1="6" x2="3" y2="6" />
                  <line x1="21" y1="14" x2="3" y2="14" />
                  <line x1="21" y1="18" x2="3" y2="18" />
                </svg>
                About Text</a>
            </li>
            <li>
              <a href="#checkbox-and-radio">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-square">
                  <polyline points="9 11 12 14 22 4" />
                  <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11" />
                </svg>
                Reservation Text</a>
            </li>
          
            <li>
              <a href="#date">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar">
                  <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
                  <line x1="16" y1="2" x2="16" y2="6" />
                  <line x1="8" y1="2" x2="8" y2="6" />
                  <line x1="3" y1="10" x2="21" y2="10" />
                </svg>
                Operational Time</a>
            </li>
            <li>
              <a href="#other">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-server">
                  <rect x="2" y="2" width="20" height="8" rx="2" ry="2" />
                  <rect x="2" y="14" width="20" height="8" rx="2" ry="2" />
                  <line x1="6" y1="6" x2="6.01" y2="6" />
                  <line x1="6" y1="18" x2="6.01" y2="18" />
                </svg>
                Footer Text</a>
            </li>
            <li>
              <a href="#contribute">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github">
                  <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22" />
                </svg>
                Social Media</a>
            </li>
          </ul>
        </nav>
      </div>
      <main class="demo-page-content">
        <section>
          <div class="href-target" id="intro"></div>
          <h1 class="package-name">Tamani CMS Settings</h1>
          <p>
            Built using Vue JS and Laravel, the Tamani Content Management System is used to manage the restaurant Menu landing page.    
          </p>
        </section>

        <section>
          <div class="href-target" id="installation"></div>
          <h1>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-tool">
              <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
            </svg>
            Contact Information
          </h1>
          <p>
              Company contact information, Include address, email and telephone number.
          </p>

          <div class="nice-form-group">
            <label>Address</label>
            <textarea type="text" v-model="contact.address"></textarea>
          </div>

          <div class="nice-form-group">
            <label>Email</label>
            <input type="text" v-model="contact.email"/>
          </div>

          <div class="nice-form-group">
            <label>Phone Number</label>
            <input type="text" v-model="contact.phone" />
          </div>

          <div class="nice-form-group">
            <label>Operational Time</label>
            <input class="w-50" type="time" v-model="contact.time_start" /> - 
            <input class="w-50" type="time" v-model="contact.time_end" />
          </div>

          <button class="theme-btn mt-3" @click="submitContact">Save Changes</button>
        </section>

        <section>
          <div class="href-target" id="structure"></div>
          <h1>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers">
              <polygon points="12 2 2 7 12 12 22 7 12 2" />
              <polyline points="2 17 12 22 22 17" />
              <polyline points="2 12 12 17 22 12" />
            </svg>
            Welcome Text
          </h1>
          <p>
            Welcome message contained on the main page of the website.
          </p>

          <div class="nice-form-group">
            <label>Title</label>
            <input type="text" v-model="welcome.title" />
          </div>

          <div class="nice-form-group">
            <label>Store Name</label>
            <input type="text" v-model="welcome.store_name" />
          </div>

          <div class="nice-form-group">
            <label>Content</label>
            <small>Additional information below the store name</small>
            <textarea name="" id="" cols="30" rows="5" v-model="welcome.description"></textarea>
          </div>

          <div class="nice-form-group">
            <label>Youtube Link</label>
            <input type="text" v-model="welcome.link" />
            <small>Only the unique code of the youtube url is fetched. <br><br>
                   https://www.youtube.com/watch?v=<strong><u>ASxR1ipTEzI</u></strong></small>
          </div>

          <button type="button" class="theme-btn mt-3" @click="submitWelcome">Save Changes</button>
        </section>

        <section>
          <div class="href-target" id="input-types"></div>
          <h1>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-align-justify">
              <line x1="21" y1="10" x2="3" y2="10" />
              <line x1="21" y1="6" x2="3" y2="6" />
              <line x1="21" y1="14" x2="3" y2="14" />
              <line x1="21" y1="18" x2="3" y2="18" />
            </svg>
            About Text
          </h1>
          <p>Introduction about our menu</p>

          <div class="nice-form-group">
            <label>Title</label>
            <input type="text" v-model="menu.title" />
          </div>

          <div class="nice-form-group">
            <label>Desription</label>
            <small>Additional information about the menu</small>
            <textarea name="" id="" cols="30" rows="5" v-model="menu.description"></textarea>
          </div>

          <div class="col w-100 d-flex justify-content-between mt-5" style="padding: 0px 10px">    
              <label class="custum-file-upload" for="file">
                  <div class="icon">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="" viewBox="0 0 24 24"><g stroke-width="0" id="SVGRepo_bgCarrier"></g><g stroke-linejoin="round" stroke-linecap="round" id="SVGRepo_tracerCarrier"></g><g id="SVGRepo_iconCarrier"> <path fill="" d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z" clip-rule="evenodd" fill-rule="evenodd"></path> </g></svg>
                  </div>
                  <div class="text">
                      <span>Click to upload image</span>
                  </div>

                  <input name="menu_image" type="file" id="file" ref="file" @input="handleSelects" accept="image/png, image/jpeg">
                  
                  {{ image_name }}
                  <p class="text-danger mt-1">{{ errImage }}</p>
              </label>

              <div v-show="!menu.gImage">
                  <div v-for="img in menu.images" :key="img">
                      <img :src="img" class="preview">
                  </div>
              </div>

              <div v-show="menu.gImage" class="w-50">
                <img style="object-fit: contain" :src="getMediaImg(menu.gImage)" class="preview">
              </div>
          </div>

          <button class="theme-btn mt-3" @click="submitAbout">Save Changes</button>
        </section>

        <section>
          <div class="href-target" id="checkbox-and-radio"></div>
          <h1>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-square">
              <polyline points="9 11 12 14 22 4" />
              <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11" />
            </svg>
            Reservation Text
          </h1>
          <p>
            Words for promotion so that customers make reservations.
          </p>

          <div class="nice-form-group">
            <label>Title</label>
            <input type="text" v-model="reservation.title" />
          </div>

          <div class="nice-form-group">
            <label>Desription</label>
            <small>Additional information about the menu</small>
            <textarea name="" id="" cols="30" rows="5" v-model="reservation.description"></textarea>
          </div>

          <div class="nice-form-group">
            <label>Additional Information</label>
            <input type="tel" v-model="reservation.additional"/>
          </div>

          <button class="theme-btn mt-3" @click="submitReservation">Save Changes</button>
        </section>

        <section>
          <div class="href-target" id="date"></div>
          <h1>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar">
              <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
              <line x1="16" y1="2" x2="16" y2="6" />
              <line x1="8" y1="2" x2="8" y2="6" />
              <line x1="3" y1="10" x2="21" y2="10" />
            </svg>
            Operational Time
          </h1>
          <p>
            The restaurant's operating time from start until closed.
          </p>

          <div class="nice-form-group">
            <label>Sunday</label>
            <input class="w-50" type="time" v-model="operational.sunday_start" /> - 
            <input class="w-50" type="time" v-model="operational.sunday_end" />
          </div>

          <div class="nice-form-group">
            <label>Monday</label>
            <input class="w-50" type="time" v-model="operational.monday_start" /> - 
            <input class="w-50" type="time" v-model="operational.monday_end" />
          </div>

           <div class="nice-form-group">
            <label>Tuesday</label>
            <input class="w-50" type="time" v-model="operational.tuesday_start" /> - 
            <input class="w-50" type="time" v-model="operational.tuesday_end" />
          </div>

          <div class="nice-form-group">
            <label>Wednesday</label>
            <input class="w-50" type="time" v-model="operational.wednesday_start" /> - 
            <input class="w-50" type="time" v-model="operational.wednesday_end" />
          </div>

          <div class="nice-form-group">
            <label>Thursday</label>
            <input class="w-50" type="time" v-model="operational.thursday_start" /> - 
            <input class="w-50" type="time" v-model="operational.thursday_end" />
          </div>

          <div class="nice-form-group">
            <label>Friday</label>
            <input class="w-50" type="time" v-model="operational.friday_start" /> - 
            <input class="w-50" type="time" v-model="operational.friday_end" />
          </div>

          <div class="nice-form-group">
            <label>Saturday</label>
            <input class="w-50" type="time" v-model="operational.saturday_start" /> - 
            <input class="w-50" type="time" v-model="operational.saturday_end" />
          </div>

          <button class="theme-btn mt-3" @click="submitOperational">Save Changes</button>
        </section>

        <section>
          <div class="href-target" id="other"></div>
          <h1>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-server">
              <rect x="2" y="2" width="20" height="8" rx="2" ry="2" />
              <rect x="2" y="14" width="20" height="8" rx="2" ry="2" />
              <line x1="6" y1="6" x2="6.01" y2="6" />
              <line x1="6" y1="18" x2="6.01" y2="18" />
            </svg>
            Footer Text
          </h1>

          <div class="nice-form-group">
            <label>Desription</label>
            <small>Additional information about the menu</small>
            <textarea name="" id="" cols="30" rows="5" v-model="footer.description"></textarea>
          </div>

          <div class="nice-form-group">
            <label>Additional Information</label>
            <input type="text" v-model="footer.additional" />
          </div>

          <button class="theme-btn mt-3" @click="submitFooter">Save Changes</button>
        </section>

        <section>
          <div class="href-target" id="contribute"></div>
          <h1>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-github">
              <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22" />
            </svg>
            Social Media
          </h1>

          <div class="nice-form-group">
            <label>Facebook</label>
            <input type="text" v-model="medsos.facebook" />
          </div>

          <div class="nice-form-group">
            <label>Instagram</label>
            <input type="text" v-model="medsos.instagram" />
          </div>

          <div class="nice-form-group">
            <label>Twitter</label>
            <input type="text" v-model="medsos.twitter" />
          </div>

          <div class="nice-form-group">
            <label>Youtube</label>
            <input type="text" v-model="medsos.youtube" />
          </div>

          <button class="theme-btn mt-3" @click="submitMedsos">Save Changes</button>
        </section>

      </main>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'SettingsView',
    data(){
      return {
        settings: [],
        contact: {
            id: null,
            content: null,
            splitedContent: [],
            address: null,
            email: null,
            phone: null,
            time: null,
            splitedTime: [],
            time_start: null,
            time_end: null,
        },
        welcome: {
            id: null,
            title: null,
            content: null,
            splitedContent: [],
            store_name: null,
            description: null,
            link: '',
        },
        menu: {
            id: null,
            title: null,
            description: null,
            image_name: null,
            image: null,
            images: [],
            gImage: null,
            errMenuImg: null,
        },
        reservation: {
            id: null,
            title: null,
            description: null,
            additional: null,
        },
        top_banner:{
            title: null,
            image_name: null,
            image: null,
            images: [],
            gImage: null,
            errTop: null,
        },
        bottom_banner: {
            title: null,
            image_name: null,
            image: null,
            images: [],
            gImage: null,
            errBottom: null
        },
        background: {
            title: null,
            image_name: null,
            image: null,
            images: [],
            gImage: null,
            errBack: null,
        },
        operational: {
            id: null,
            title: null,
            content: null,
            splitedContent: [],
            sunday: null,
            monday: null,
            tuesday: null,
            wednesday: null,
            thursday: null,
            friday: null,
            saturday: null,
            sunday_start: '',
            sunday_end: '',
            monday_start: '',
            monday_end: '',
            tuesday_start: '',
            tuesday_end: '',
            wednesday_start:'',
            wednesday_end: '',
            thursday_start: '',
            thursday_end: '',
            friday_start: '',
            friday_end: '',
            saturday_start:'',
            saturday_end: '',
        },
        footer: {
            id: null,
            description: null,
            additional: null,
        },
        logo: {
            title: null,
            image_name: null,
            image: null,
            images: [],
            gImage: null,
            errLogo: null,
        },
        medsos: {
            id: null,
            content: null,
            splitedContent: null,
            facebook: null,
            instagram: null,
            twitter: null,
            youtube: null,
        }
      }
    },
    mounted(){
      this.getSettings()
    },
    methods: {
        async getSettings(){
          const validToken = this.$store.getters.GET_AUTH_TOKEN;
          const config = {
              headers: { Authorization: `Bearer ` + validToken}
          }

          try{
              let { data } = await axios.get('/settings', config)
              this.settings = data

              this.contact.id = data.contact.id
              this.contact.title = data.contact.title;
              this.contact.content = data.contact.content;
              this.contact.splitedContent = this.contact.content.split("<br>");
              this.contact.address = this.contact.splitedContent[0];
              this.contact.email = this.contact.splitedContent[2];
              this.contact.phone = this.contact.splitedContent[1];
              this.contact.time = data.contact.additional;
              this.contact.splitedTime = this.contact.time.split("-");
              this.contact.time_start = String(this.contact.splitedTime[0]);
              this.contact.time_end = String(this.contact.splitedTime[1]);

              this.welcome.id = data.welcome.id
              this.welcome.title = data.welcome.title;
              this.welcome.content = data.welcome.content;
              this.welcome.link = data.welcome.additional;
              this.welcome.splitedContent = this.welcome.content.split("<br>");
              this.welcome.store_name = this.welcome.splitedContent[0];
              this.welcome.description = this.welcome.splitedContent[1];

              this.menu.id          = data.menu.id
              this.menu.title       = data.menu.title
              this.menu.description = data.menu.content
              this.menu.gImage  = data.menu.additional

              this.reservation.id = data.reservation.id
              this.reservation.title = data.reservation.title
              this.reservation.description = data.reservation.content
              this.reservation.additional = data.reservation.additional

              this.footer.id = data.footer.id
              this.footer.description = data.footer.content
              this.footer.additional = data.footer.additional

              this.top_banner.gImage = data.top.content
              this.bottom_banner.gImage = data.bottom.content
              this.background.gImage = data.background.content
              this.logo.gImage = data.logo.content

              this.operational.id = data.operational.id
              this.operational.content = data.operational.content
              this.operational.splitedContent = this.operational.content.split("<br>")
              this.operational.sunday = this.operational.splitedContent[0];
              this.operational.monday = this.operational.splitedContent[1];
              this.operational.tuesday = this.operational.splitedContent[2];
              this.operational.wednesday = this.operational.splitedContent[3];
              this.operational.thursday = this.operational.splitedContent[4];
              this.operational.friday = this.operational.splitedContent[5];
              this.operational.saturday = this.operational.splitedContent[6];
              this.operational.sunday_start = this.operational.sunday.split("-")[0];
              this.operational.sunday_end = this.operational.sunday.split("-")[1];
              this.operational.monday_start = this.operational.monday.split("-")[0];
              this.operational.monday_end = this.operational.monday.split("-")[1];
              this.operational.tuesday_start = this.operational.tuesday.split("-")[0];
              this.operational.tuesday_end = this.operational.tuesday.split("-")[1];
              this.operational.wednesday_start = this.operational.wednesday.split("-")[0];
              this.operational.wednesday_end = this.operational.wednesday.split("-")[1];
              this.operational.thursday_start = this.operational.thursday.split("-")[0];
              this.operational.thursday_end = this.operational.thursday.split("-")[1];
              this.operational.friday_start = this.operational.friday.split("-")[0];
              this.operational.friday_end = this.operational.friday.split("-")[1];
              this.operational.saturday_start = this.operational.saturday.split("-")[0];
              this.operational.saturday_end = this.operational.saturday.split("-")[1];

              this.medsos.id = data.medsos.id;
              this.medsos.content = data.medsos.content;
              this.medsos.splitedContent = this.medsos.content.split("<br>");
              this.medsos.facebook = this.medsos.splitedContent[0];
              this.medsos.instagram = this.medsos.splitedContent[1];
              this.medsos.twitter = this.medsos.splitedContent[2];
              this.medsos.youtube = this.medsos.splitedContent[3];
          } catch(e) {
              console.log(e)
          }
        },
        getMediaImg(filename){
          return this.assets + '/images/media/' + filename;
        },
        submitContact(){
          const validToken = this.$store.getters.GET_AUTH_TOKEN;
          const config = {
                headers: { Authorization: `Bearer ` + validToken}
          }

          const data = {
              'id': this.contact.id,
              'content' : this.contact.address + '<br>' + this.contact.phone + '<br>' + this.contact.email,
              'additional' : this.contact.time_start + '-' + this.contact.time_end,
          }

          axios.post('/settings', data, config)
          .then(() => {
              window.location.href = "/settings"
          })
          .catch((error) => {
            console.log(error);
          });
        },
        submitWelcome(){
          const validToken = this.$store.getters.GET_AUTH_TOKEN;
          const config = {
                headers: { Authorization: `Bearer ` + validToken}
          }

          const data = {
              'id': this.welcome.id,
              'title': this.welcome.title,
              'content' : this.welcome.store_name + '<br>' + this.welcome.description,
              'additional' : this.welcome.link,
          }

          axios.post('/settings', data, config)
          .then(() => {
              window.location.href = "/settings"
          })
          .catch((error) => {
            console.log(error);
          });
        },
        submitAbout(){
            const validToken = this.$store.getters.GET_AUTH_TOKEN;

            let formData = new FormData();
            formData.append('image', this.menu.image);
            formData.append('id', this.menu.id);
            formData.append('title', this.menu.title);
            formData.append('content', this.menu.description);

            axios.post('/settings', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': '*',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ` + validToken,
                }
            })
            .then(() => {
                window.location.href = "/settings"
            }).catch(err => {
                console.log(err)
            });
        },
        submitFooter(){
          const validToken = this.$store.getters.GET_AUTH_TOKEN;
          const config = {
                headers: { Authorization: `Bearer ` + validToken}
          }

          const data = {
              'id': this.footer.id,
              'content' : this.footer.description,
              'additional' : this.footer.additional,
          }

          axios.post('/settings', data, config)
          .then(() => {
              window.location.href = "/settings"
          })
          .catch((error) => {
            console.log(error);
          });
        },
        submitReservation(){
          const validToken = this.$store.getters.GET_AUTH_TOKEN;
          const config = {
                headers: { Authorization: `Bearer ` + validToken}
          }

          const data = {
              'id': this.reservation.id,
              'title': this.reservation.title,
              'content' : this.reservation.description,
              'additional' : this.reservation.additional,
          }

          axios.post('/settings', data, config)
          .then(() => {
              window.location.href = "/settings"
          })
          .catch((error) => {
            console.log(error);
          });
        },
        submitOperational(){
          const validToken = this.$store.getters.GET_AUTH_TOKEN;
          const config = {
                headers: { Authorization: `Bearer ` + validToken}
          }

          const data = {
              'id': this.operational.id,
              'content' : this.operational.sunday_start + '-' + this.operational.sunday_end + '<br>' +
                          this.operational.monday_start + '-' + this.operational.monday_end + '<br>' +
                          this.operational.tuesday_start + '-' + this.operational.tuesday_end + '<br>' +
                          this.operational.wednesday_start + '-' + this.operational.wednesday_end + '<br>' +
                          this.operational.thursday_start + '-' + this.operational.thursday_end + '<br>' +
                          this.operational.friday_start + '-' + this.operational.friday_end + '<br>' +
                          this.operational.saturday_start + '-' + this.operational.saturday_end + '<br>',
          }

          axios.post('/settings', data, config)
          .then(() => {
              window.location.href = "/settings"
          })
          .catch((error) => {
            console.log(error);
          });
        },
        submitMedsos(){
          const validToken = this.$store.getters.GET_AUTH_TOKEN;
          const config = {
                headers: { Authorization: `Bearer ` + validToken}
          }

          const data = {
              'id': this.medsos.id,
              'content' : this.medsos.facebook + '<br>' + 
                          this.medsos.instagram + '<br>' + 
                          this.medsos.twitter + '<br>' +
                          this.medsos.youtube,
          }

          axios.post('/settings', data, config)
          .then(() => {
              window.location.href = "/settings"
          })
          .catch((error) => {
            console.log(error);
          });
        },
        handleSelects(e) {
            this.menu.images = [];
            this.menu.gImage = null;
            this.menu.image_name = e.target.files[0].name;
            this.menu.image = e.target.files[0];
            let fileList = Array.prototype.slice.call(e.target.files);
            fileList.forEach(f => {
                if(!f.type.match("image.*")) {
                    return;
                }

                let reader = new FileReader();
                let that = this.menu;

                reader.onload = function (e) {
                    that.images.push(e.target.result);
                }

                reader.readAsDataURL(f); 
            })
        },
    }
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: initial;
}
article,
aside,
details,
figcaption,
figure,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.nice-form-group {
  --nf-input-size: 1rem;
  --nf-input-font-size: calc(var(--nf-input-size) * 0.875);
  --nf-small-font-size: calc(var(--nf-input-size) * 0.875);
  --nf-input-font-family: inherit;
  --nf-label-font-family: inherit;
  --nf-input-color: #20242f;
  --nf-input-border-radius: 0.25rem;
  --nf-input-placeholder-color: #929292;
  --nf-input-border-color: #c0c4c9;
  --nf-input-border-width: 1px;
  --nf-input-border-style: solid;
  --nf-input-border-bottom-width: 2px;
  --nf-input-focus-border-color: #3b4ce2;
  --nf-input-background-color: #f9fafb;
  --nf-invalid-input-border-color: var(--nf-input-border-color);
  --nf-invalid-input-background-color: var(--nf-input-background-color);
  --nf-invalid-input-color: var(--nf-input-color);
  --nf-valid-input-border-color: var(--nf-input-border-color);
  --nf-valid-input-background-color: var(--nf-input-background-color);
  --nf-valid-input-color: inherit;
  --nf-invalid-input-border-bottom-color: red;
  --nf-valid-input-border-bottom-color: green;
  --nf-label-font-size: var(--nf-small-font-size);
  --nf-label-color: #374151;
  --nf-label-font-weight: 500;
  --nf-slider-track-background: #dfdfdf;
  --nf-slider-track-height: 0.25rem;
  --nf-slider-thumb-size: calc(var(--nf-slider-track-height) * 4);
  --nf-slider-track-border-radius: var(--nf-slider-track-height);
  --nf-slider-thumb-border-width: 2px;
  --nf-slider-thumb-border-focus-width: 1px;
  --nf-slider-thumb-border-color: #fff;
  --nf-slider-thumb-background: var(--nf-input-focus-border-color);
  display: block;
  margin-top: calc(var(--nf-input-size) * 1.5);
  line-height: 1;
  white-space: nowrap;
  --switch-orb-size: var(--nf-input-size);
  --switch-orb-offset: calc(var(--nf-input-border-width) * 2);
  --switch-width: calc(var(--nf-input-size) * 2.5);
  --switch-height: calc(var(--nf-input-size) * 1.25 + var(--switch-orb-offset));
}
.nice-form-group > label {
  font-weight: var(--nf-label-font-weight);
  display: block;
  color: var(--nf-label-color);
  font-size: var(--nf-label-font-size);
  font-family: var(--nf-label-font-family);
  margin-bottom: calc(var(--nf-input-size) / 2);
  white-space: normal;
}
.nice-form-group > label + small {
  font-style: normal;
}
.nice-form-group small {
  font-family: var(--nf-input-font-family);
  display: block;
  font-weight: 400;
  opacity: 0.75;
  font-size: var(--nf-small-font-size);
  margin-bottom: calc(var(--nf-input-size) * 0.75);
}
.nice-form-group small:last-child {
  margin-bottom: 0;
}
.nice-form-group > legend {
  font-weight: var(--nf-label-font-weight);
  display: block;
  color: var(--nf-label-color);
  font-size: var(--nf-label-font-size);
  font-family: var(--nf-label-font-family);
  margin-bottom: calc(var(--nf-input-size) / 5);
}
.nice-form-group > .nice-form-group {
  margin-top: calc(var(--nf-input-size) / 2);
}
.nice-form-group > input[type="checkbox"],
.nice-form-group > input[type="date"],
.nice-form-group > input[type="email"],
.nice-form-group > input[type="month"],
.nice-form-group > input[type="number"],
.nice-form-group > input[type="password"],
.nice-form-group > input[type="radio"],
.nice-form-group > input[type="search"],
.nice-form-group > input[type="tel"],
.nice-form-group > input[type="text"],
.nice-form-group > input[type="time"],
.nice-form-group > input[type="url"],
.nice-form-group > input[type="week"],
.nice-form-group > select,
.nice-form-group > textarea {
  background: var(--nf-input-background-color);
  font-family: inherit;
  font-size: var(--nf-input-font-size);
  border-bottom-width: var(--nf-input-border-width);
  font-family: var(--nf-input-font-family);
  box-shadow: none;
  border-radius: var(--nf-input-border-radius);
  border: var(--nf-input-border-width) var(--nf-input-border-style)
    var(--nf-input-border-color);
  border-bottom: var(--nf-input-border-bottom-width)
    var(--nf-input-border-style) var(--nf-input-border-color);
  color: var(--nf-input-color);
  width: 100%;
  padding: calc(var(--nf-input-size) * 0.75);
  height: calc(var(--nf-input-size) * 2.75);
  line-height: normal;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.15s ease-out;
  --icon-padding: calc(var(--nf-input-size) * 2.25);
  --icon-background-offset: calc(var(--nf-input-size) * 0.75);
}
.nice-form-group
  > input[type="checkbox"]:required:not(:placeholder-shown):invalid,
.nice-form-group > input[type="date"]:required:not(:placeholder-shown):invalid,
.nice-form-group > input[type="email"]:required:not(:placeholder-shown):invalid,
.nice-form-group > input[type="month"]:required:not(:placeholder-shown):invalid,
.nice-form-group
  > input[type="number"]:required:not(:placeholder-shown):invalid,
.nice-form-group
  > input[type="password"]:required:not(:placeholder-shown):invalid,
.nice-form-group > input[type="radio"]:required:not(:placeholder-shown):invalid,
.nice-form-group
  > input[type="search"]:required:not(:placeholder-shown):invalid,
.nice-form-group > input[type="tel"]:required:not(:placeholder-shown):invalid,
.nice-form-group > input[type="text"]:required:not(:placeholder-shown):invalid,
.nice-form-group > input[type="time"]:required:not(:placeholder-shown):invalid,
.nice-form-group > input[type="url"]:required:not(:placeholder-shown):invalid,
.nice-form-group > input[type="week"]:required:not(:placeholder-shown):invalid,
.nice-form-group > select:required:not(:placeholder-shown):invalid,
.nice-form-group > textarea:required:not(:placeholder-shown):invalid {
  background-color: var(--nf-invalid-input-background-color);
  border-bottom-color: var(--nf-valid-input-border-color);
  border-color: var(--nf-valid-input-border-color)
    var(--nf-valid-input-border-color)
    var(--nf-invalid-input-border-bottom-color);
  color: var(--nf-invalid-input-color);
}
.nice-form-group
  > input[type="checkbox"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="date"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="email"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="month"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="number"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="password"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="radio"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="search"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="tel"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="text"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="time"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="url"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="week"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group > select:required:not(:placeholder-shown):invalid:focus,
.nice-form-group > textarea:required:not(:placeholder-shown):invalid:focus {
  background-color: var(--nf-input-background-color);
  border-color: var(--nf-input-border-color);
  color: var(--nf-input-color);
}
.nice-form-group
  > input[type="checkbox"]:required:not(:placeholder-shown):valid,
.nice-form-group > input[type="date"]:required:not(:placeholder-shown):valid,
.nice-form-group > input[type="email"]:required:not(:placeholder-shown):valid,
.nice-form-group > input[type="month"]:required:not(:placeholder-shown):valid,
.nice-form-group > input[type="number"]:required:not(:placeholder-shown):valid,
.nice-form-group
  > input[type="password"]:required:not(:placeholder-shown):valid,
.nice-form-group > input[type="radio"]:required:not(:placeholder-shown):valid,
.nice-form-group > input[type="search"]:required:not(:placeholder-shown):valid,
.nice-form-group > input[type="tel"]:required:not(:placeholder-shown):valid,
.nice-form-group > input[type="text"]:required:not(:placeholder-shown):valid,
.nice-form-group > input[type="time"]:required:not(:placeholder-shown):valid,
.nice-form-group > input[type="url"]:required:not(:placeholder-shown):valid,
.nice-form-group > input[type="week"]:required:not(:placeholder-shown):valid,
.nice-form-group > select:required:not(:placeholder-shown):valid,
.nice-form-group > textarea:required:not(:placeholder-shown):valid {
  background-color: var(--nf-valid-input-background-color);
  border-bottom-color: var(--nf-valid-input-border-color);
  border-color: var(--nf-valid-input-border-color)
    var(--nf-valid-input-border-color) var(--nf-valid-input-border-bottom-color);
  color: var(--nf-valid-input-color);
}
.nice-form-group > input[type="checkbox"]:disabled,
.nice-form-group > input[type="date"]:disabled,
.nice-form-group > input[type="email"]:disabled,
.nice-form-group > input[type="month"]:disabled,
.nice-form-group > input[type="number"]:disabled,
.nice-form-group > input[type="password"]:disabled,
.nice-form-group > input[type="radio"]:disabled,
.nice-form-group > input[type="search"]:disabled,
.nice-form-group > input[type="tel"]:disabled,
.nice-form-group > input[type="text"]:disabled,
.nice-form-group > input[type="time"]:disabled,
.nice-form-group > input[type="url"]:disabled,
.nice-form-group > input[type="week"]:disabled,
.nice-form-group > select:disabled,
.nice-form-group > textarea:disabled {
  cursor: not-allowed;
  opacity: 0.75;
}
.nice-form-group > input[type="checkbox"]::-webkit-input-placeholder,
.nice-form-group > input[type="date"]::-webkit-input-placeholder,
.nice-form-group > input[type="email"]::-webkit-input-placeholder,
.nice-form-group > input[type="month"]::-webkit-input-placeholder,
.nice-form-group > input[type="number"]::-webkit-input-placeholder,
.nice-form-group > input[type="password"]::-webkit-input-placeholder,
.nice-form-group > input[type="radio"]::-webkit-input-placeholder,
.nice-form-group > input[type="search"]::-webkit-input-placeholder,
.nice-form-group > input[type="tel"]::-webkit-input-placeholder,
.nice-form-group > input[type="text"]::-webkit-input-placeholder,
.nice-form-group > input[type="time"]::-webkit-input-placeholder,
.nice-form-group > input[type="url"]::-webkit-input-placeholder,
.nice-form-group > input[type="week"]::-webkit-input-placeholder,
.nice-form-group > select::-webkit-input-placeholder,
.nice-form-group > textarea::-webkit-input-placeholder {
  color: var(--nf-input-placeholder-color);
  letter-spacing: 0;
}
.nice-form-group > input[type="checkbox"]:-ms-input-placeholder,
.nice-form-group > input[type="date"]:-ms-input-placeholder,
.nice-form-group > input[type="email"]:-ms-input-placeholder,
.nice-form-group > input[type="month"]:-ms-input-placeholder,
.nice-form-group > input[type="number"]:-ms-input-placeholder,
.nice-form-group > input[type="password"]:-ms-input-placeholder,
.nice-form-group > input[type="radio"]:-ms-input-placeholder,
.nice-form-group > input[type="search"]:-ms-input-placeholder,
.nice-form-group > input[type="tel"]:-ms-input-placeholder,
.nice-form-group > input[type="text"]:-ms-input-placeholder,
.nice-form-group > input[type="time"]:-ms-input-placeholder,
.nice-form-group > input[type="url"]:-ms-input-placeholder,
.nice-form-group > input[type="week"]:-ms-input-placeholder,
.nice-form-group > select:-ms-input-placeholder,
.nice-form-group > textarea:-ms-input-placeholder {
  color: var(--nf-input-placeholder-color);
  letter-spacing: 0;
}
.nice-form-group > input[type="checkbox"]:-moz-placeholder,
.nice-form-group > input[type="checkbox"]::-moz-placeholder,
.nice-form-group > input[type="date"]:-moz-placeholder,
.nice-form-group > input[type="date"]::-moz-placeholder,
.nice-form-group > input[type="email"]:-moz-placeholder,
.nice-form-group > input[type="email"]::-moz-placeholder,
.nice-form-group > input[type="month"]:-moz-placeholder,
.nice-form-group > input[type="month"]::-moz-placeholder,
.nice-form-group > input[type="number"]:-moz-placeholder,
.nice-form-group > input[type="number"]::-moz-placeholder,
.nice-form-group > input[type="password"]:-moz-placeholder,
.nice-form-group > input[type="password"]::-moz-placeholder,
.nice-form-group > input[type="radio"]:-moz-placeholder,
.nice-form-group > input[type="radio"]::-moz-placeholder,
.nice-form-group > input[type="search"]:-moz-placeholder,
.nice-form-group > input[type="search"]::-moz-placeholder,
.nice-form-group > input[type="tel"]:-moz-placeholder,
.nice-form-group > input[type="tel"]::-moz-placeholder,
.nice-form-group > input[type="text"]:-moz-placeholder,
.nice-form-group > input[type="text"]::-moz-placeholder,
.nice-form-group > input[type="time"]:-moz-placeholder,
.nice-form-group > input[type="time"]::-moz-placeholder,
.nice-form-group > input[type="url"]:-moz-placeholder,
.nice-form-group > input[type="url"]::-moz-placeholder,
.nice-form-group > input[type="week"]:-moz-placeholder,
.nice-form-group > input[type="week"]::-moz-placeholder,
.nice-form-group > select:-moz-placeholder,
.nice-form-group > select::-moz-placeholder,
.nice-form-group > textarea:-moz-placeholder,
.nice-form-group > textarea::-moz-placeholder {
  color: var(--nf-input-placeholder-color);
  letter-spacing: 0;
}
.nice-form-group > input[type="checkbox"]:focus,
.nice-form-group > input[type="date"]:focus,
.nice-form-group > input[type="email"]:focus,
.nice-form-group > input[type="month"]:focus,
.nice-form-group > input[type="number"]:focus,
.nice-form-group > input[type="password"]:focus,
.nice-form-group > input[type="radio"]:focus,
.nice-form-group > input[type="search"]:focus,
.nice-form-group > input[type="tel"]:focus,
.nice-form-group > input[type="text"]:focus,
.nice-form-group > input[type="time"]:focus,
.nice-form-group > input[type="url"]:focus,
.nice-form-group > input[type="week"]:focus,
.nice-form-group > select:focus,
.nice-form-group > textarea:focus {
  outline: none;
  border-color: var(--nf-input-focus-border-color);
}
.nice-form-group > input[type="checkbox"] + small,
.nice-form-group > input[type="date"] + small,
.nice-form-group > input[type="email"] + small,
.nice-form-group > input[type="month"] + small,
.nice-form-group > input[type="number"] + small,
.nice-form-group > input[type="password"] + small,
.nice-form-group > input[type="radio"] + small,
.nice-form-group > input[type="search"] + small,
.nice-form-group > input[type="tel"] + small,
.nice-form-group > input[type="text"] + small,
.nice-form-group > input[type="time"] + small,
.nice-form-group > input[type="url"] + small,
.nice-form-group > input[type="week"] + small,
.nice-form-group > select + small,
.nice-form-group > textarea + small {
  margin-top: 0.5rem;
}
.nice-form-group > input[type="checkbox"].icon-left,
.nice-form-group > input[type="date"].icon-left,
.nice-form-group > input[type="email"].icon-left,
.nice-form-group > input[type="month"].icon-left,
.nice-form-group > input[type="number"].icon-left,
.nice-form-group > input[type="password"].icon-left,
.nice-form-group > input[type="radio"].icon-left,
.nice-form-group > input[type="search"].icon-left,
.nice-form-group > input[type="tel"].icon-left,
.nice-form-group > input[type="text"].icon-left,
.nice-form-group > input[type="time"].icon-left,
.nice-form-group > input[type="url"].icon-left,
.nice-form-group > input[type="week"].icon-left,
.nice-form-group > select.icon-left,
.nice-form-group > textarea.icon-left {
  background-position: left var(--icon-background-offset) bottom 50%;
  padding-left: var(--icon-padding);
  background-size: var(--nf-input-size);
}
.nice-form-group > input[type="checkbox"].icon-right,
.nice-form-group > input[type="date"].icon-right,
.nice-form-group > input[type="email"].icon-right,
.nice-form-group > input[type="month"].icon-right,
.nice-form-group > input[type="number"].icon-right,
.nice-form-group > input[type="password"].icon-right,
.nice-form-group > input[type="radio"].icon-right,
.nice-form-group > input[type="search"].icon-right,
.nice-form-group > input[type="tel"].icon-right,
.nice-form-group > input[type="text"].icon-right,
.nice-form-group > input[type="time"].icon-right,
.nice-form-group > input[type="url"].icon-right,
.nice-form-group > input[type="week"].icon-right,
.nice-form-group > select.icon-right,
.nice-form-group > textarea.icon-right {
  background-position: right var(--icon-background-offset) bottom 50%;
  padding-right: var(--icon-padding);
  background-size: var(--nf-input-size);
}
.nice-form-group > input[type="checkbox"]:-webkit-autofill,
.nice-form-group > input[type="date"]:-webkit-autofill,
.nice-form-group > input[type="email"]:-webkit-autofill,
.nice-form-group > input[type="month"]:-webkit-autofill,
.nice-form-group > input[type="number"]:-webkit-autofill,
.nice-form-group > input[type="password"]:-webkit-autofill,
.nice-form-group > input[type="radio"]:-webkit-autofill,
.nice-form-group > input[type="search"]:-webkit-autofill,
.nice-form-group > input[type="tel"]:-webkit-autofill,
.nice-form-group > input[type="text"]:-webkit-autofill,
.nice-form-group > input[type="time"]:-webkit-autofill,
.nice-form-group > input[type="url"]:-webkit-autofill,
.nice-form-group > input[type="week"]:-webkit-autofill,
.nice-form-group > select:-webkit-autofill,
.nice-form-group > textarea:-webkit-autofill {
  padding: calc(var(--nf-input-size) * 0.75) !important;
}
.nice-form-group > input[type="search"]:placeholder-shown {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-search'%3E%3Ccircle cx='11' cy='11' r='8'/%3E%3Cpath d='M21 21l-4.35-4.35'/%3E%3C/svg%3E");
  background-position: left calc(var(--nf-input-size) * 0.75) bottom 50%;
  padding-left: calc(var(--nf-input-size) * 2.25);
  background-size: var(--nf-input-size);
  background-repeat: no-repeat;
}
.nice-form-group > input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  width: var(--nf-input-size);
  height: var(--nf-input-size);
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3E%3Cpath d='M18 6L6 18M6 6l12 12'/%3E%3C/svg%3E");
}
.nice-form-group > input[type="search"]:focus {
  padding-left: calc(var(--nf-input-size) * 0.75);
  background-position: left calc(var(--nf-input-size) * -1) bottom 50%;
}
.nice-form-group > input[type="email"][class^="icon"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-at-sign'%3E%3Ccircle cx='12' cy='12' r='4'/%3E%3Cpath d='M16 8v5a3 3 0 006 0v-1a10 10 0 10-3.92 7.94'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}
.nice-form-group > input[type="tel"][class^="icon"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-phone'%3E%3Cpath d='M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}
.nice-form-group > input[type="url"][class^="icon"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-link'%3E%3Cpath d='M10 13a5 5 0 007.54.54l3-3a5 5 0 00-7.07-7.07l-1.72 1.71'/%3E%3Cpath d='M14 11a5 5 0 00-7.54-.54l-3 3a5 5 0 007.07 7.07l1.71-1.71'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}
.nice-form-group > input[type="password"] {
  letter-spacing: 2px;
}
.nice-form-group > input[type="password"][class^="icon"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-lock'%3E%3Crect x='3' y='11' width='18' height='11' rx='2' ry='2'/%3E%3Cpath d='M7 11V7a5 5 0 0110 0v4'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}
.nice-form-group > input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  cursor: pointer;
}
.nice-form-group > input[type="range"]:focus {
  outline: none;
}
.nice-form-group > input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: var(--nf-slider-track-height);
  background: var(--nf-slider-track-background);
  border-radius: var(--nf-slider-track-border-radius);
}
.nice-form-group > input[type="range"]::-moz-range-track {
  width: 100%;
  height: var(--nf-slider-track-height);
  background: var(--nf-slider-track-background);
  border-radius: var(--nf-slider-track-border-radius);
}
.nice-form-group > input[type="range"]::-webkit-slider-thumb {
  height: var(--nf-slider-thumb-size);
  width: var(--nf-slider-thumb-size);
  border-radius: var(--nf-slider-thumb-size);
  background: var(--nf-slider-thumb-background);
  border: 0;
  border: var(--nf-slider-thumb-border-width) solid
    var(--nf-slider-thumb-border-color);
  -webkit-appearance: none;
  appearance: none;
  margin-top: calc(
    var(--nf-slider-track-height) * 0.5 - var(--nf-slider-thumb-size) * 0.5
  );
}
.nice-form-group > input[type="range"]::-moz-range-thumb {
  height: var(--nf-slider-thumb-size);
  width: var(--nf-slider-thumb-size);
  border-radius: var(--nf-slider-thumb-size);
  background: var(--nf-slider-thumb-background);
  border: 0;
  border: var(--nf-slider-thumb-border-width) solid
    var(--nf-slider-thumb-border-color);
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
}
.nice-form-group > input[type="range"]:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 var(--nf-slider-thumb-border-focus-width)
    var(--nf-slider-thumb-background);
}
.nice-form-group > input[type="range"]:focus::-moz-range-thumb {
  box-shadow: 0 0 0 var(--nf-slider-thumb-border-focus-width)
    var(--nf-slider-thumb-background);
}
.nice-form-group > input[type="color"] {
  border: var(--nf-input-border-width) solid var(--nf-input-border-color);
  border-bottom-width: var(--nf-input-border-bottom-width);
  height: calc(var(--nf-input-size) * 2);
  border-radius: var(--nf-input-border-radius);
  padding: calc(var(--nf-input-border-width) * 2);
}
.nice-form-group > input[type="color"]:focus {
  outline: none;
  border-color: var(--nf-input-focus-border-color);
}
.nice-form-group > input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 5%;
}
.nice-form-group > input[type="color"]::-moz-color-swatch {
  border-radius: calc(var(--nf-input-border-radius) / 2);
  border: none;
}
.nice-form-group > input[type="color"]::-webkit-color-swatch {
  border-radius: calc(var(--nf-input-border-radius) / 2);
  border: none;
}
.nice-form-group > input[type="number"] {
  width: auto;
}
.nice-form-group > input[type="date"],
.nice-form-group > input[type="month"],
.nice-form-group > input[type="week"] {
  min-width: 14em;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-calendar'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'/%3E%3Cpath d='M16 2v4M8 2v4M3 10h18'/%3E%3C/svg%3E");
}
.nice-form-group > input[type="time"] {
  min-width: 6em;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-clock'%3E%3Ccircle cx='12' cy='12' r='10'/%3E%3Cpath d='M12 6v6l4 2'/%3E%3C/svg%3E");
}
.nice-form-group > input[type="date"],
.nice-form-group > input[type="month"],
.nice-form-group > input[type="time"],
.nice-form-group > input[type="week"] {
  background-position: right calc(var(--nf-input-size) * 0.75) bottom 50%;
  background-repeat: no-repeat;
  background-size: var(--nf-input-size);
  width: auto;
}
.nice-form-group > input[type="date"]::-webkit-calendar-picker-indicator,
.nice-form-group > input[type="date"]::-webkit-inner-spin-button,
.nice-form-group > input[type="month"]::-webkit-calendar-picker-indicator,
.nice-form-group > input[type="month"]::-webkit-inner-spin-button,
.nice-form-group > input[type="time"]::-webkit-calendar-picker-indicator,
.nice-form-group > input[type="time"]::-webkit-inner-spin-button,
.nice-form-group > input[type="week"]::-webkit-calendar-picker-indicator,
.nice-form-group > input[type="week"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  cursor: pointer;
  opacity: 0;
}
@-moz-document url-prefix() {
  .nice-form-group > input[type="date"],
  .nice-form-group > input[type="month"],
  .nice-form-group > input[type="time"],
  .nice-form-group > input[type="week"] {
    min-width: auto;
    width: auto;
    background-image: none;
  }
}
.nice-form-group > textarea {
  height: auto;
}
.nice-form-group > input[type="checkbox"],
.nice-form-group > input[type="radio"] {
  width: var(--nf-input-size);
  height: var(--nf-input-size);
  padding: inherit;
  margin: 0;
  display: inline-block;
  vertical-align: top;
  border-radius: calc(var(--nf-input-border-radius) / 2);
  border-width: var(--nf-input-border-width);
  cursor: pointer;
  background-position: 50%;
}
.nice-form-group > input[type="checkbox"]:focus:not(:checked),
.nice-form-group > input[type="radio"]:focus:not(:checked) {
  border: var(--nf-input-border-width) solid var(--nf-input-focus-border-color);
  outline: none;
}
.nice-form-group > input[type="checkbox"]:hover,
.nice-form-group > input[type="radio"]:hover {
  border: var(--nf-input-border-width) solid var(--nf-input-focus-border-color);
}
.nice-form-group > input[type="checkbox"] + label,
.nice-form-group > input[type="radio"] + label {
  display: inline-block;
  margin-bottom: 0;
  padding-left: calc(var(--nf-input-size) / 2.5);
  font-weight: 400;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  max-width: calc(100% - var(--nf-input-size) * 2);
  line-height: normal;
}
.nice-form-group > input[type="checkbox"] + label > small,
.nice-form-group > input[type="radio"] + label > small {
  margin-top: calc(var(--nf-input-size) / 4);
}
.nice-form-group > input[type="checkbox"]:checked {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23FFF' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3E%3Cpath d='M20 6L9 17l-5-5'/%3E%3C/svg%3E")
    no-repeat 50%/85%;
  background-color: var(--nf-input-focus-border-color);
  border-color: var(--nf-input-focus-border-color);
}
.nice-form-group > input[type="radio"] {
  border-radius: 100%;
}
.nice-form-group > input[type="radio"]:checked {
  background-color: var(--nf-input-focus-border-color);
  border-color: var(--nf-input-focus-border-color);
  box-shadow: inset 0 0 0 3px #fff;
}
.nice-form-group > input[type="checkbox"].switch {
  width: var(--switch-width);
  height: var(--switch-height);
  border-radius: var(--switch-height);
  position: relative;
}
.nice-form-group > input[type="checkbox"].switch:after {
  background: var(--nf-input-border-color);
  border-radius: var(--switch-orb-size);
  height: var(--switch-orb-size);
  left: var(--switch-orb-offset);
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: var(--switch-orb-size);
  content: "";
  transition: all 0.2s ease-out;
}
.nice-form-group > input[type="checkbox"].switch + label {
  margin-top: calc(var(--switch-height) / 8);
}
.nice-form-group > input[type="checkbox"].switch:checked {
  background: none;
  background-position: 0 0;
  background-color: var(--nf-input-focus-border-color);
}
.nice-form-group > input[type="checkbox"].switch:checked:after {
  -webkit-transform: translateY(-50%)
    translateX(calc(var(--switch-width) / 2 - var(--switch-orb-offset)));
  transform: translateY(-50%)
    translateX(calc(var(--switch-width) / 2 - var(--switch-orb-offset)));
  background: #fff;
}
// .nice-form-group > input[type="file"] {
//   background: rgba(0, 0, 0, 0.025);
//   padding: var(--nf-input-size);
//   display: block;
//   width: 100%;
//   border-radius: var(--nf-input-border-radius);
//   border: 1px dashed var(--nf-input-border-color);
//   outline: none;
//   cursor: pointer;
// }
// .nice-form-group > input[type="file"]:focus,
// .nice-form-group > input[type="file"]:hover {
//   border-color: var(--nf-input-focus-border-color);
// }
// .nice-form-group > input[type="file"]::file-selector-button {
//   background: var(--nf-input-focus-border-color);
//   border: 0;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
//   padding: 0.5rem;
//   border-radius: var(--nf-input-border-radius);
//   color: #fff;
//   margin-right: 1rem;
//   outline: none;
//   font-family: var(--nf-input-font-family);
//   cursor: pointer;
// }
// .nice-form-group > input[type="file"]::-webkit-file-upload-button {
//   background: var(--nf-input-focus-border-color);
//   border: 0;
//   -webkit-appearance: none;
//   appearance: none;
//   padding: 0.5rem;
//   border-radius: var(--nf-input-border-radius);
//   color: #fff;
//   margin-right: 1rem;
//   outline: none;
//   font-family: var(--nf-input-font-family);
//   cursor: pointer;
// }
.nice-form-group > select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-position: right calc(var(--nf-input-size) * 0.75) bottom 50%;
  background-repeat: no-repeat;
  background-size: var(--nf-input-size);
}
*,
:after,
:before {
  box-sizing: inherit;
}
html {
  font-size: 16px;
  box-sizing: border-box;
}
.demo-page {
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  max-width: 55em;
}
.demo-page .demo-page-navigation {
  width: 18em;
  padding: 2em 1em;
}
@media only screen and (max-width: 750px) {
  .demo-page .demo-page-navigation {
    display: none;
  }
}
.demo-page .demo-page-navigation nav {
  position: -webkit-sticky;
  position: sticky;
  top: 2em;
  background: #fff;
  padding: 0.5em;
  border-radius: 0.75rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.demo-page .demo-page-navigation a {
  display: -webkit-flex;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
  border-radius: 0.25em;
  color: #374151;
  -webkit-align-items: center;
  align-items: center;
}
.demo-page .demo-page-navigation a:hover {
  background: #f3f4f6;
}
.demo-page .demo-page-navigation a svg {
  width: 1.25em;
  height: 1.25em;
  margin-right: 1em;
  color: #1f2937;
}
.demo-page .demo-page-content {
  padding: 2em 1em;
  max-width: 100%;
}
@media only screen and (min-width: 750px) {
  .demo-page .demo-page-content {
    width: calc(100% - 18em);
  }
}
.href-target {
  position: absolute;
  top: -2em;
}
.to-repo,
.to-reset {
  display: -webkit-inline-flex;
  display: inline-flex;
  background: #24292e;
  color: #fff;
  border-radius: 5px;
  padding: 0.5em 1em;
  text-decoration: none;
  -webkit-align-items: center;
  align-items: center;
  transition: background 0.2s ease-out;
}
.to-repo:hover,
.to-reset:hover {
  background: #000;
}
.to-repo svg,
.to-reset svg {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.75em;
}
.to-reset {
  background: #3b4ce2;
}
.to-reset:hover {
  background: #2538df;
}
section {
  background: #fff;
  padding: 2em;
  border-radius: 0.75rem;
  line-height: 1.6;
  overflow: hidden;
  margin-bottom: 2rem;
  position: relative;
  font-size: 0.875rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
section h1 {
  font-weight: 500;
  font-size: 1.25rem;
  color: #000;
  margin-bottom: 0.75rem;
}
section h1 svg {
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: -10%;
  margin-right: 0.25em;
}
section h1.package-name {
  font-size: 2rem;
  margin-bottom: 0.75rem;
  margin-top: -0.5rem;
}
section strong {
  font-weight: 500;
  color: #000;
}
section p {
  margin: 0.5rem 0 1.5rem;
}
section p a {
  text-decoration: none;
  font-weight: 500;
  color: #3b4ce2;
}
section p:last-child {
  margin-bottom: 0;
}
section code {
  font-weight: 500;
  font-family: Consolas, monaco, monospace;
  position: relative;
  z-index: 1;
  margin: 0 2px;
  background: #f3f4f4;
  content: "";
  border-radius: 3px;
  padding: 2px 5px;
  white-space: nowrap;
}
section ul {
  margin-top: 0.5em;
  padding-left: 1em;
  list-style-type: disc;
}
details {
  background: #f1f1f1;
  margin: 2em -2em -2em;
  padding: 1.5em 2em;
}
details .gist {
  margin-top: 1.5em;
}
details .toggle-code {
  display: inline-block;
  padding: 0.5em 1em;
  border-radius: 5px;
  font-size: 0.875rem;
  background: #10b981;
  top: 1em;
  right: 1em;
  color: #fff;
  font-weight: 500;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
details .toggle-code:hover {
  background: #0ea271;
}
details .toggle-code svg {
  display: inline-block;
  vertical-align: -15%;
  margin-right: 0.25em;
}
details summary {
  outline: none;
  list-style-type: none;
}
details summary::marker {
  display: none;
}
details summary::-webkit-details-marker {
  display: none;
}
</style>