<template>
 <div class="modal-overlay">
    <div class="exmodal" v-show="!isDeleted">
        <svg @click="closeBtn" class="close-btn" width="32px" height="32px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#e60000" stroke="#e60000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#c70000" d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"></path></g></svg>
      <h6>Confirmation</h6>
      <p>Are you sure want to delete {{ itemname }} ?</p>
      
      <div class="row d-flex justify-content-between" style="padding: 20px 30px">
        <button class="modal-btn-confirm" @click="deleteItem">Confirm</button>
        <button class="modal-btn-denied" @click="closeBtn">Cancel</button>
      </div>
    </div>

    <div class="exmodal" v-show="isDeleted">
      <div v-show="!isError">
        <lord-icon
            src="https://cdn.lordicon.com/qjwkduhc.json"
            trigger="loop"
            delay="1000"
            colors="primary:#848484,secondary:#9abc16,tertiary:#ffffff"
            state="morph-trash-in"
            style="width:120px;height:120px">
        </lord-icon>
        <p>{{ itemname }} Deleted</p>

        <div class="row d-flex justify-content-center" style="margin-top: -60px;">
          <a :href="'/'+itemredirect" class="modal-btn-confirm" style="padding-top: 10px;">OK</a>
        </div>
      </div>
      
      <div v-show="isError">
        <lord-icon
            src="https://cdn.lordicon.com/inrunzby.json"
            trigger="loop"
            delay="2000"
            colors="primary:#ffc738,secondary:#92140c"
            style="width:120px;height:120px;margin-top: -30px;">
        </lord-icon>
        <p class="text-danger">Error : {{ message }}</p>

        <div class="row d-flex justify-content-center" style="margin-top: -60px;">
          <button @click="closeBtn" class="modal-btn-confirm" >OK</button>
        </div>
      </div>
      
      <div class="row d-flex justify-content-between" style="padding: 20px 30px">
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'ModalView',
    props: ['itemid', 'itemname', 'itemuri', 'itemredirect'],
    data(){
        return{
          isDeleted: false,
          isError: false,
          message: null,
        }
    },
    methods: {
        closeBtn(){
            this.$emit('closed', false)
        },
        async deleteItem(){
          const validToken = this.$store.getters.GET_AUTH_TOKEN;
          const config = {
              headers: { Authorization: `Bearer ` + validToken}
          }

          try {
            let { data } = await axios.delete('/' + this.itemuri +'/' + this.itemid, config)
            this.message = data.message;
            this.isDeleted = true;
            this.isError = false;
          } catch(e) {
            this.isDeleted = true;
            this.isError = true;
            this.message = e.response.data.error;
          }
        },
    }
}
</script>

<style>
.close-btn{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    transition: .3s ease;
}

.close-btn:hover{
    transform: scale(120%);
}

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.exmodal {
  position: absolute;
  top: 100px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  text-align: center;
  background-color: white;
  width: 400px;
  height: 300px;
  padding: 60px 0;
  border-radius: 20px;
}

.check {
  width: 150px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0;
}

p {
  font-size: 16px;
  margin: 20px 0;
}

.modal-btn-confirm {
  background-color: rgb(152,188,22);
  width: 150px;
  height: 40px;
  color: white;
  font-size: 14px;
  border-radius: 16px;
  margin-top: 50px;
}

.modal-btn-confirm:hover {
  background-color: rgb(84, 108, 0);
  color: rgb(255, 255, 255);
}

.modal-btn-denied {
  background-color: rgb(252, 48, 48);
  width: 150px;
  height: 40px;
  color: rgb(255, 255, 255);
  font-size: 14px;
  border-radius: 16px;
  margin-top: 50px;
}

.modal-btn-denied:hover {
  background-color: rgb(131, 14, 14);
  color: rgb(255, 255, 255);
}
</style>