<template>
    <div class="content-wrapper" style="padding-top: 30px">
    <h1 class="title-text">Manage Food Menu</h1>
        <!-- <div class="restaurant-wrapper">

            <Splide :options="{ rewind: true, 
                                perPage: 4, 
                                pagination: false,
                                type: 'loop', 
                                drag: 'free',
                                arrows: true}" 
                    class="slider-wrapper"
                    aria-label="My Favorite Images">
                <SplideSlide v-for="ppl in outlet" :key="ppl.id">
                    <div class="restaurant-card">
                        <div class="card__img" v-if="ppl.image">
                            <img class="restaurant-img" :src="getRestaurantImg(ppl.image)" alt="">
                        </div>
                        <div class="card__subtitle">{{ ppl.type }}</div>
                        <div class="card__wrapper">
                            <div class="card__title">{{ ppl.name }}</div>
                            <div class="card__icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" style="user-select: none; width: 100%; height: 100%; display: inline-block; fill: rgb(224, 223, 220); flex-shrink: 0; cursor: auto;" color="rgb(224, 223, 220)"><g color="rgb(224, 223, 220)"><circle cx="128" cy="128" r="96" opacity="0.2"></circle><circle cx="128" cy="128" r="96" fill="none" stroke="rgb(224, 223, 220)" stroke-miterlimit="10" stroke-width="16"></circle><polyline points="134.1 161.9 168 128 134.1 94.1" fill="none" stroke="rgb(224, 223, 220)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline><line x1="88" y1="128" x2="168" y2="128" fill="none" stroke="rgb(224, 223, 220)" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line></g></svg></div>
                        </div>
                    </div> 
                </SplideSlide>
            </Splide>
        </div> -->

        <div class="nav-selector">
            <label class="radio" v-for="ctg in category" :key="ctg.id">
                <input type="radio" name="radio" :value="ctg.id" v-model="selectedNav">
                <span class="name">{{ ctg.name }}</span>
            </label>
        </div>

        <div class="content restaurant-table-wrapper" v-for="ctg in category" :key="ctg.id">
            <div class="container-fluid" v-show="selectedNav == ctg.id">
                <div class="row">
                    <div class="col-12">
                        <div class="table-card">
                            <div class="card-header">
                                <h3 class="card-title">Data Menu</h3>
                            </div>
                
                            <div class="card-body">
                                <router-link :to="{ name: 'add-food' }">
                                    <button class="btn btn-theme align-self-end">
                                        <i class="nav-icon fas fa-file" id="nav-icon" color="white"> Add Menu</i>
                                    </button>
                                </router-link>
        
                                <table id="datatable" class="table thead-dark table-bordered table-striped mt-2">
                                    <thead class="thead-dark">
                                        <tr>
                                        <th>ID</th>
                                        <th>Nama</th>
                                        <th>Description</th>
                                        <th>image</th>
                                        <!-- <th>Total Option</th> -->
                                        <th width="120px">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="fd in ctg.food" :key="fd.id">
                                            <td>{{ fd.id }}</td>
                                            <td>{{ fd.name }}</td>
                                            <td><small>{{ fd.description }}</small></td>
                                            <td><img class="restaurant-thmb" :src="getFoodImg(fd.image)" alt=""></td>
                                            <!-- <td>{{ fd.count_option }}</td> -->
                                            <td>
                                            <div class="d-flex justify-content-arround">
                                                <router-link :to="{ name: 'edit-food', params: { id : fd.id }}">
                                                    <button class="btn btn-sm btn-outline-success">Edit</button>
                                                </router-link>
                                                <button class="btn btn-sm btn-outline-danger ml-3" @click="show(fd.id, fd.name, 'food')">Delete</button>
                                            </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ModalVue :itemid="selectedId" :itemname="selectedName" :itemuri="selectedURI" :itemredirect="redirect" v-show="showModal" @closed="onClickChild" />
    </div>
</template>

<script>
import axios from 'axios'
import ModalVue from '@/components/Modal.vue'

export default {
    name: 'FoodView',
    components: {
        ModalVue
    },
    data(){
        return {
            food: [],
            category: [],
            selectedNav: 1,
            selectedId: null,
            selectedName: null,
            selectedURI: null,
            showModal: false,
            redirect: null,
        }
    },
    mounted(){
        this.getFood()
        this.getCategories()
    },
    methods: {
        async getCategories(){
            const validToken = this.$store.getters.GET_AUTH_TOKEN;
            const config = {
                headers: { Authorization: `Bearer ` + validToken}
            }

            try{
                let { data } = await axios.get('/category', config)
                this.category = data.data
            } catch(e) {
                console.log(e)
            }
        },
        async getFood(){
            const validToken = this.$store.getters.GET_AUTH_TOKEN;
            const config = {
                headers: { Authorization: `Bearer ` + validToken}
            }

            try{
                let { data } = await axios.get('/food', config)
                this.food = data.data
            } catch(e) {
                console.log(e)
            }
        },
        getFoodImg(filename){
            return this.assets + '/images/food/' + filename;
        },
        show(id, name, uri){
            this.selectedId = id;
            this.selectedName = name;
            this.selectedURI = uri;
            this.showModal = true;
            this.redirect = this.$route.name
        },
        onClickChild(value){
            this.showModal = value
        }
    },

}
</script>

<style>

</style>